import { NineActions, NineButton } from "@9amhealth/wcl/generated/react";
import type { FC } from "react";
import React, { useEffect } from "react";
import { IconLargeStethoscope } from "src/constants/icons";
import { formatPhoneNumberNational } from "src/lib/formatPhoneNumber";
import formatZipCode from "src/lib/formatZipCode";
import toTitleCase from "src/lib/toTitleCase";
import translate from "src/lib/translate";
import PatientPCPBloc from "src/state/PatientPCPBloc/PatientPCPBloc";
import { useBloc } from "src/state/state";
import AppPageTitle from "src/ui/components/AppPageTitle/AppPageTitle";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import Data from "src/ui/components/Data/Data";
import { PlaceholderWrapper } from "src/ui/components/PharmacyInformationDetail/PharmacyInformationDetail";
import { PROFILE_URL } from "src/ui/components/ProfileOverview/ProfileOverview";
import OverlayBackButton from "src/ui/components/StyledComponents/OverlayBackButton";
import Translate from "src/ui/components/Translate/Translate";
import {
  AppPopup,
  useAppQueryPopup
} from "../AppQueryPopups/AppQueryPopupsBloc";

export const PROFILE_PCP_INFO_ROUTE = "/app/profile/primary-care-physician";

const PCPInformationDetail: FC = () => {
  const [{ loading, pcp }, { loadPCP }] = useBloc(PatientPCPBloc);

  useEffect(() => {
    void loadPCP();
  }, []);

  const [openSelect] = useAppQueryPopup(AppPopup.profileSelectPCP, {
    onClose: () => {
      void loadPCP();
    }
  });

  return (
    <div>
      <OverlayBackButton show alwaysUseFallback fallbackUrl={PROFILE_URL} />
      <AppPageTitle title={translate("primaryCarePhysician")}></AppPageTitle>
      <nine-spacer s={"xl"}></nine-spacer>

      {!pcp && !loading && (
        <>
          <PlaceholderWrapper>
            <IconLargeStethoscope />
            <p className="color-c-60 as-subhead strong m0">
              <Translate msg="pcp.notAdded" />
            </p>
          </PlaceholderWrapper>

          <nine-spacer s={"sm"}></nine-spacer>
          <NineActions style={{ justifyContent: "center" }}>
            <NineButton onClick={() => openSelect()}>
              <Translate msg="addPcp" />
            </NineButton>
          </NineActions>
        </>
      )}

      <AsyncContent height="20rem" check={[loading === false]}>
        {pcp && (
          <Data.Table
            title={<Translate msg="myPcp" />}
            action={{
              onAction: openSelect
            }}
          >
            <Data.Row
              title={translate("name")}
              data={`Dr. ${pcp.provider.firstName} ${pcp.provider.lastName}`}
            />
            {pcp.provider.providerAddress && (
              <>
                <Data.Row
                  title={translate("street")}
                  data={toTitleCase(pcp.provider.providerAddress.street)}
                />
                {pcp.provider.providerAddress.aptSuite && (
                  <Data.Row
                    title={translate("aptSuite")}
                    data={toTitleCase(pcp.provider.providerAddress.aptSuite)}
                  />
                )}
                <Data.Row
                  title={translate("city")}
                  data={toTitleCase(pcp.provider.providerAddress.city)}
                />
                <Data.Row
                  title={translate("state")}
                  data={pcp.provider.providerAddress.state}
                />
                <Data.Row
                  title={translate("zip")}
                  data={formatZipCode(pcp.provider.providerAddress.zip)}
                />
              </>
            )}
            {pcp.provider.phone && (
              <Data.Row
                title={translate("phone.short")}
                data={formatPhoneNumberNational(pcp.provider.phone, false)}
              />
            )}
            {pcp.provider.fax && (
              <Data.Row
                title={translate("fax")}
                data={formatPhoneNumberNational(pcp.provider.fax, false)}
              />
            )}
          </Data.Table>
        )}
      </AsyncContent>
    </div>
  );
};

export default PCPInformationDetail;
